<template>
  <div class="quiz-container">

    <h2 class="quiz-title">Quiz Question</h2>
    <div class="isgetnew-content" v-if="this.questions?.length == 0">
      <div class="loadingbox">
        <img :src="loading_img" alt="">
        <span>loading...</span>
      </div>
    </div>
    <div class="questionbox" v-else>
      <p class="quiz-question">
        {{ question?.questionText }}
      </p>
      <div class="options-container">
        <div v-for="(option, index) in question?.options" :key="index" :class="[
          'option',
          {
            correct:
              question?.ischooes && question?.correctAnswerIndex == index,
            incorrect:
              question?.ischooes &&
              question?.chooese == index &&
              question?.correctAnswerIndex != question?.chooese,
          },
        ]" @click="selectOption(index)">
          <span class="option-icon">{{ String.fromCharCode(65 + index) }}</span>
          {{ option }}
          <i class="arrow"><span class="option-icon" v-if="question?.ischooes > 0">{{
            question?.ischooes &&
              question?.chooese == index &&
              question?.correctAnswerIndex != question?.chooese
              ? "✖"
              : question?.ischooes && question?.correctAnswerIndex == index
                ? "✔"
                : "×"
          }}</span></i>
        </div>
      </div>
    </div>
    <!-- <button v-if="ischooes > 0" class="next-question" @click="nextQuestion">
      Next Question
    </button> -->
    <div class="card-navigation" v-if="questions?.length > 0">
      <span class="nav-arrow" @click.stop="prevQuestion()">
        <i class="el-icon-back"></i> Return</span>
      <span class="card-count">{{ questionIndex + 1 }} / {{ questions?.length }}</span>
      <span class="nav-arrow" @click.stop="nextQuestion()">
        Next <i class="el-icon-right"></i></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    questionsI: {
      type: Array,
      default: () => [],
    },
    noteobj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {

      loading_img: require("@/assets/images/noteindex/loading_img1.gif"),

      questions: [],
      questionIndex: 0,
      question: {},

      timer: 10, // 倒计时初始值
      isgetnew: false, // 标记计时器是否正在运行
    };
  },
  watch: {
    questionsI(val) {
      this.questions = val;
      if (this.questionIndex >= this.questions.length) {
        this.questionIndex = 0;
      }
      this.question = this.questions[this.questionIndex];
      this.$set(this.question, "ischooes", false);
    },
  },
  mounted() {
    this.questions = this.questionsI;
    if (this.questions.length == 0) {
      this.getnewQuestion();
    } else {
      this.question = this.questions[this.questionIndex];
      this.$set(this.question, "ischooes", false);
    }

    // console.log(this.questionsI, this.question);
  },
  methods: {
    async getnewQuestion() {
      let text = '';
      this.noteobj?.live_transcript?.part_objs.forEach((part) => {
        text += part.notes;
      });
      if (text == '') {
        text = this.noteobj?.note_translation;
      }

      const res = await this.$axios.post("api/textaction/", {
        noteid: this.noteobj.noteid,
        language: this.noteobj.language,
        action: "text2quiz",
        text: text,
      });
      const redata = res.data;
      if (redata.code == 200) {
        // 轮训结果
        this.pollForFlashcards(redata.noteid, 'quiz');
      }
    },
    async pollForFlashcards(noteid, key) {
      const INTERVAL = 2000;  // 每次轮询间隔 (毫秒)
      const MAX_TRIES = 100;   // 最大轮询次数
      for (let attempt = 0; attempt < MAX_TRIES; attempt++) {
        try {
          // 查询 flashcards 生成状态
          const response = await this.$axios.post("api/getnotes_bykey/", { noteid: noteid, key: key });
          if (response.data.code === 200) {
            // 生成成功，处理 flashcards 数据
            // this.handleFlashcards(response.data.flashcards);
            this.questions = response.data.note_quiz[0];
            this.$emit("setquiz", this.questions);
            for (let i = 0; i < this.questions?.length; i++) {
              // ischooes: false,
              // chooese: -1,
              this.$set(this.questions[i], "ischooes", false);
              this.$set(this.questions[i], "chooese", -1);
            }
            this.question = this.questions[this.questionIndex];
            this.$set(this.question, "ischooes", false);
            
            return; // 退出轮询
          }
        } catch (error) {
          console.error("Polling error: ", error);
        }
        // 如果未完成，等待一段时间再重试
        await new Promise(resolve => setTimeout(resolve, INTERVAL));
      }

      console.error("Max attempts reached. Flashcards generation failed.");
    },
    selectOption(index) {
      if (this.question?.ischooes) return;
      // 这里可以添加选择问题的逻辑
      this.question = {
        ...this.question,
        ischooes: true,
        chooese: index,
      };
    },
    prevQuestion() {
      // 这里可以添加切换到上一个问题的逻辑
      // console.log("Prev question");
      this.questionIndex--;
      if (this.questionIndex < 0) {
        this.questionIndex = this.questions.length - 1;
      }
      this.question = this.questions[this.questionIndex];
      // this.question?.ischooes = false;
    },
    nextQuestion() {
      // 这里可以添加切换到下一个问题的逻辑
      // console.log("Next question");
      this.questionIndex++;
      if (this.questionIndex >= this.questions.length) {
        this.questionIndex = 0;
      }
      this.question = this.questions[this.questionIndex];
      // this.question?.ischooes = false;
    },
  },
};
</script>

<style lang="less">
.quiz-container {
  color: white;
  padding: 0 2vw;
  font-family: Arial, sans-serif;
  position: relative;
  overflow: hidden;
}

.isgetnew-content {

  .loadingbox {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100px;
    }

    span {
      font-size: 20px;
    }
  }

}

.quiz-title {
  display: flex;
  align-items: center;
  font-size: 2vw;
  margin: 0;
  margin-bottom: 10px;
}

.quiz-icon {
  margin-right: 10px;
  font-size: 38px;
}

.quiz-question {
  font-size: 1vw;
  margin-bottom: 20px;
}

.options-container {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.option {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  padding: 1vw;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgb(49, 54, 68);
  }

  &.correct {
    background: rgb(108, 92, 231);
  }

  &.incorrect {
    background-color: red;
  }
}

.option-icon {
  width: 40px;
  height: 40px;
  font-size: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  font-weight: bold;
  box-shadow: 0px 0px 5px 0px #fff;
}

.arrow {
  margin-left: auto;
  font-size: 34px;
  color: #fff;
}

.card-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 20px auto;

  .nav-arrow {
    font-size: 0.9vw;
    margin: 0 10px;
    cursor: pointer;
  }

  .card-count {
    font-size: 20px;
    margin: 0 30px;
  }
}

.next-question {
  background-color: #6200ee;
  color: white;
  border: none;
  padding: 15px 25px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  margin-top: 20px;
  margin-left: 50%;
  translate: -50%;
}

.confetti-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  animation: confetti-fall 3s linear infinite;
}

@keyframes confetti-fall {
  0% {
    transform: translateY(-100%) rotate(0deg);
  }

  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}
</style>
