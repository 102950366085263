<template>
  <div class="modal-chaoxian" v-if="isVisible">
    <div class="chaoxian-content">
      <div class="chaoxian-header">
        <img :src="leave_img" alt="">
        <h2>Kindly save your audio before <br> leaving this page to  avoid data loss.</h2>
      </div>
      <div class="okbox">
        <button class="generate-button save" @click="saveaudio">
          Save audio locally
        </button>
        <!-- <button class="generate-button" @click="confirm">
          Generate notes
        </button> -->
        <button class="generate-button cancel" @click="cancel">
          No thanks
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
  },
  data() {
    return {
      leave_img: require('@/assets/images/home/record_leave.png'),
      isVisible: false
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    saveaudio() {
      this.$emit('saveaudio');
    },
    cancel() {
      this.$emit('cancel');
    },
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
  }
}
</script>
<style lang="less" scoped>
.chaoxian-header {
  h2 {
    min-width: 450px;
    font-size: 18px !important;
    span {
      color: red;
    }
  }
}

.okbox {
  display: flex;
  flex-direction: column;

  .generate-button {
    margin-bottom: 10px;
    background-color: rgb(22 163 74);

    &:hover {
      background-color: rgb(18 143 65);
    }
  }
  .save {
    background-color: rgb(196, 66, 66);
    &:hover {
      background-color: rgb(203, 15, 4);
    }
  }
  .cancel {
    background-color: #f0f2f5;
    color: #111;

    &:hover {
      background-color: #d0d2d6;
    }
  }
}
</style>