<template>
    <div class="modal-chaoxian" v-if="isVisible">

        <div class="chaoxian-content">
            <div class="uploading" v-if="percentage < 100">
                <div class="chaoxian-header">
                    <img :src="uploading" alt="">
                    <h2>The data is being saved, please do not close the page</h2>

                    <el-progress text-color="#000" :text-inside="true" :stroke-width="26"
                        :percentage="percentage"></el-progress>
                </div>
            </div>
            <div v-if="percentage >= 100">
                <div class="chaoxian-header">
                    <img :src="savesuccess" alt="">
                    <h3>Saved successfully</h3>
                    <h2>Saved successfully. The audio is only saved for 24 hours. <br> If the audio is very important to
                        you,
                        please save it locally in time.</h2>
                </div>
                <div class="okbox">
                    <button class="generate-button" @click="saveaudio">
                        Save Audio Locally
                    </button>
                    <button class="generate-button cancel" @click="closeModal">
                        No thanks
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        textcontent: {
            type: String,
            default: "测试提醒字段",
        },
        tonextname: {
            type: String,
        },
        percentage: {
            type: Number,
            default: 0,
        },
    },
    components: {
    },
    data() {
        return {
            uploading: require("@/assets/images/noteindex/loading_img1.gif"),
            savesuccess: require("@/assets/images/home/savesuccess.png"),

            isVisible: false,

        };
    },
    methods: {
        saveaudio() {
            this.$emit("saveaudio", this.tonextname);
            this.isVisible = false;
        },
        openModal() {
            this.isVisible = true;
        },
        closeModal() {
            this.$emit("cancel", this.tonextname);
            this.isVisible = false;
        },
    },
};
</script>

<style lang="less">
.modal-chaoxian {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    .chaoxian-content {
        background-color: rgb(56, 53, 74);
        border-radius: 10px;
        padding: 2vw;
    }

    .chaoxian-header {
        text-align: center;
        margin-bottom: 20px;

        img {
            width: 100px;
        }

        h3 {
            font-size: 22px;
            color: rgb(71, 208, 115);
            ;
        }

        h2 {
            color: white;
            font-size: 20px;
            margin: 25px 0px;

            span {
                color: red;
                font-weight: bold;
            }
        }

    }

    .okbox {
        text-align: right;
        display: flex;
        justify-content: center;
        align-items: center;

        .generate-button {
            background-color: rgb(209, 63, 63);
            color: white;
            border: none;
            border-radius: 8px;
            padding: 0.6vw 1vw;
            font-size: 17px;
            cursor: pointer;
            transition: background-color 0.3s;
            margin-left: 1vw;
            width: 200px;

            &:hover {
                background-color: #6d28d9;
            }
        }

        .cancel {
            background-color: rgb(229, 222, 244);
            color: #110e2b;

            &:hover {
                background-color: #c0ace1;
            }
        }
    }






}
</style>