var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quiz-container"},[_c('h2',{staticClass:"quiz-title"},[_vm._v("Quiz Question")]),(this.questions?.length == 0)?_c('div',{staticClass:"isgetnew-content"},[_c('div',{staticClass:"loadingbox"},[_c('img',{attrs:{"src":_vm.loading_img,"alt":""}}),_c('span',[_vm._v("loading...")])])]):_c('div',{staticClass:"questionbox"},[_c('p',{staticClass:"quiz-question"},[_vm._v(" "+_vm._s(_vm.question?.questionText)+" ")]),_c('div',{staticClass:"options-container"},_vm._l((_vm.question?.options),function(option,index){return _c('div',{key:index,class:[
        'option',
        {
          correct:
            _vm.question?.ischooes && _vm.question?.correctAnswerIndex == index,
          incorrect:
            _vm.question?.ischooes &&
            _vm.question?.chooese == index &&
            _vm.question?.correctAnswerIndex != _vm.question?.chooese,
        },
      ],on:{"click":function($event){return _vm.selectOption(index)}}},[_c('span',{staticClass:"option-icon"},[_vm._v(_vm._s(String.fromCharCode(65 + index)))]),_vm._v(" "+_vm._s(option)+" "),_c('i',{staticClass:"arrow"},[(_vm.question?.ischooes > 0)?_c('span',{staticClass:"option-icon"},[_vm._v(_vm._s(_vm.question?.ischooes && _vm.question?.chooese == index && _vm.question?.correctAnswerIndex != _vm.question?.chooese ? "✖" : _vm.question?.ischooes && _vm.question?.correctAnswerIndex == index ? "✔" : "×"))]):_vm._e()])])}),0)]),(_vm.questions?.length > 0)?_c('div',{staticClass:"card-navigation"},[_c('span',{staticClass:"nav-arrow",on:{"click":function($event){$event.stopPropagation();return _vm.prevQuestion()}}},[_c('i',{staticClass:"el-icon-back"}),_vm._v(" Return")]),_c('span',{staticClass:"card-count"},[_vm._v(_vm._s(_vm.questionIndex + 1)+" / "+_vm._s(_vm.questions?.length))]),_c('span',{staticClass:"nav-arrow",on:{"click":function($event){$event.stopPropagation();return _vm.nextQuestion()}}},[_vm._v(" Next "),_c('i',{staticClass:"el-icon-right"})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }