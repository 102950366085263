<template>
  <div class="flashcards-container">
    <div class="header">
      <div class="title">
        <div class="subtitle">
          <h1>Flashcards</h1>
          <span>{{ flashcards?.length }} flashcards to study</span>
        </div>
      </div>
    </div>
    <div class="isgetnew-content" v-if="this.flashcards?.length == 0">
      <div class="loadingbox">
        <img :src="loading_img" alt="">
        <span>loading...</span>
      </div>
    </div>
    <div class="cardbox" v-else>
      <div class="card">
        <div class="card-inner" @click="flipCard" :class="{ 'is-flipped': isFlipped }">
          <div class="card-front" v-if="!isFlipped">
            <div class="card-content">{{ currentCard?.question }}</div>
          </div>
          <div class="card-back" v-else>
            <div class="card-content">{{ currentCard?.answer }}</div>
          </div>
        </div>
      </div>
      <div class="card-navigation">
        <span class="nav-arrow" @click.stop="prevCard">
          <i class="el-icon-back"></i> Return</span>
        <span class="card-count">{{ currentIndex + 1 }} / {{ flashcards?.length }}</span>
        <span class="nav-arrow" @click.stop="nextCard">
          Next <i class="el-icon-right"></i></span>
      </div>
    </div>

    <!-- <div class="action-buttons">
      <button class="action-button" @click="cardedit = !cardedit">
        <span class="button-icon">✏️</span>
        Edit
        <span class="arrow el-icon-arrow-right"></span>
      </button>
      <button class="action-button">
        <span class="button-icon">📚</span>
        Flashcards
        <span class="arrow el-icon-arrow-right"></span>
      </button>
    </div> -->
    <div class="flashcard-list">
      <div class="flashcard-item" v-for="(item, index) in flashcards" :key="index">
        <div class="row">
          <div class="showbox" v-if="!(cardedit && editindex == index)">
            <div class="item-term">
              <p>{{ item.question }}</p>
            </div>
            <div class="xiefen">
              <div class="xie"></div>
            </div>
            <div class="item-definition">{{ item.answer }}</div>
          </div>
          <div class="showbox" v-if="cardedit && editindex == index">
            <div class="item-term">
              <textarea v-model="item.question" class="tera-term"></textarea>
            </div>
            <div class="xiefen">
              <div class="xie"></div>
            </div>
            <div class="item-definition">
              <textarea v-model="item.answer" class="tera-term"></textarea>
            </div>
          </div>
          <div class="editbox">
            <div v-if="!(cardedit && editindex == index)" @click="
              cardedit = !cardedit;
            editindex = index;
            " class="editiconbox">
              <img class="editicon" :src="editicon" alt="Edit" />
            </div>
            <div class="editoverbox" v-if="cardedit && editindex == index">
              <div @click="savechangecard()">
                <img class="editover" :src="editok" alt="" />
              </div>
              <div @click="cardedit = false">
                <img :src="editclose" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    noteobj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading_img:require("@/assets/images/noteindex/loading_img1.gif"),
      editicon: require("@/assets/images/noteindex/edit_icon.png"),
      editok: require("@/assets/images/noteindex/edit_ok.png"),
      editclose: require("@/assets/images/noteindex/edit_close.png"),
      flashcards: [],
      currentIndex: 0,
      isFlipped: false,
      cardedit: false,
      editindex: 0,

      timer: null,
      isgetnew: false, //

    };
  },
  computed: {
    currentCard() {
      if (!this.flashcards[this.currentIndex]) return null;
      return this.flashcards[this.currentIndex];
    },
  },
  mounted() {

    this.flashcards = this.noteobj.note_cards;
    if (this.flashcards.length == 0) {
      console.log("No flashcards found for this note");
      this.getnewflashcards();
    }
  },
  methods: {
    async getnewflashcards() {
      let text = '';
      this.noteobj?.live_transcript?.part_objs.forEach((part) => {
        text += part.notes;
      });
      if (text == '') {
        text = this.noteobj?.note_translation;
      }

      const res = await this.$axios.post("api/textaction/", {
        noteid: this.noteobj.noteid,
        language: this.noteobj.language,
        action: "text2flashcard",
        text: text,
      });
      const redata = res.data;
      if (redata.code == 200) {
        // 轮训结果
        this.pollForFlashcards(redata.noteid, 'flashcards');
      }
    },
    async pollForFlashcards(noteid, key) {
      const INTERVAL = 2000;  // 每次轮询间隔 (毫秒)
      const MAX_TRIES = 100;   // 最大轮询次数
      for (let attempt = 0; attempt < MAX_TRIES; attempt++) {
        try {
          // 查询 flashcards 生成状态
          const response = await this.$axios.post("api/getnotes_bykey/", { noteid: noteid, key: key });
          if (response.data.code === 200) {
            // 生成成功，处理 flashcards 数据
            // this.handleFlashcards(response.data.flashcards);
            this.flashcards = response.data.note_cards[0];
            this.$emit("setflashcards", this.flashcards);
            return; // 退出轮询
          }
        } catch (error) {
          console.error("Polling error: ", error);
        }
        // 如果未完成，等待一段时间再重试
        await new Promise(resolve => setTimeout(resolve, INTERVAL));
      }

      console.error("Max attempts reached. Flashcards generation failed.");
    },
    savechangecard() {
      this.cardedit = false;
      // 保存数据到数据库
      this.$axios.post("api/savechangecard/", {
        flashcards: JSON.stringify(this.flashcards),
        noteid: this.noteobj.noteid,
      });


    },
    flipCard() {
      this.isFlipped = !this.isFlipped;
    },
    nextCard() {
      this.currentIndex = (this.currentIndex + 1) % this.flashcards.length;
      this.isFlipped = false;
    },
    prevCard() {
      this.currentIndex =
        (this.currentIndex - 1 + this.flashcards.length) %
        this.flashcards.length;
      this.isFlipped = false;
    },
  },
};
</script>

<style lang="less" scoped>
/* 确保整个容器可滚动 */
.flashcards-container {
  overflow-y: auto;
  /* 允许垂直滚动 */
  margin-bottom: 50px;
}

.flashcards-container {
  color: white;
  padding: 0 2vw;
  font-family: Arial, sans-serif;
}

.header {
  .title {
    font-weight: bold;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
      font-size: 48px;
    }

    .subtitle {
      h1 {
        padding: 0px;
        margin: 0px;
        font-family: Outfit;
        font-size: 2vw;
        margin-bottom: 10px;
      }

      span {
        font-weight: normal;
      }
    }
  }
}

.cardbox {
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  margin-bottom: 20px;
  text-align: center;

  .card {
    margin-bottom: 15px;

    .card-inner {
      margin: 0 auto;
      border-radius: 5px;
      padding: 12px;
      height: 18vw;
      width: 36vw;
      background: rgba(255, 255, 255, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: transform 0.6s;
      transform-style: preserve-3d;
      cursor: pointer;

      &.is-flipped {
        transform: rotateX(180deg);
      }
    }

    .card-front,
    .card-back {
      backface-visibility: hidden;
      overflow: auto;
    }

    .card-back {
      transform: rotateX(180deg);
      padding: 0.9vw;
      text-align: center;
    }

    .card-content {
      font-size: 20px;
    }
  }

  .card-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 38vw;
    margin: 0px auto;

    .nav-arrow {
      font-size: 0.9vw;
      margin: 0 10px;
      cursor: pointer;
    }

    .card-count {
      font-size: 20px;
      margin: 0 30px;
    }
  }
}

.action-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  .action-button {
    flex: 1;
    background-color: rgb(33, 36, 46);
    border: none;
    font-size: 16px;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .button-icon {
      margin-right: 10px;
      font-size: 35px;
    }

    .arrow {
      font-size: 28px;
    }
  }
}

.flashcard-list {
  max-width: 78vw;

  .flashcard-item {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    margin-bottom: 10px;

    .row {
      position: relative;

      .editbox {
        height: 8vw;
        position: absolute;
        right: -3vw;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .editoverbox {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .editover {
          max-width: 2vw;
        }

        .editiconbox {
          padding-right: 0.5vw;
        }

        .editicon {
          max-width: 1.3vw;
        }

        img {
          cursor: pointer;
          margin-top: 0.5vw;
        }
      }
    }

    .showbox {
      display: flex;
      align-items: center;
      font-size: 0.9vw;
      height: 8vw;

      .item-term {
        padding: 0px 12px;
        flex: 3;
        font-weight: bold;
        height: 7vw;
        overflow-y: auto;
        display: flex;
        align-items: center;

        p {
          padding-left: 2vw;
        }
      }

      .xiefen {
        width: 8vw;
        height: 8vw;

        .xie {
          position: relative;
          overflow: hidden;
          width: 8vw;
          height: 8vw;
        }

        .xie::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 141%;
          /* 约为 √2 * 100% */
          height: 4px;
          background: #09052590;
          transform: translate(-50%, -50%) rotate(130deg);
        }
      }

      .item-definition {
        height: 6vw;
        overflow-y: auto;
        padding: 0px 12px;
        flex: 3;
        height: 7vw;
        color: #fff;
        display: flex;
        align-items: center;
      }

      .tera-term {
        font-size: 0.9vw;
        width: calc(100% - 5px);
        height: calc(100% - 0.5vw);
        margin: 0px;
        padding: 0px;
        background: transparent;
        color: #fff;
        border: none;
        border-bottom: 1px solid #fff;
        // 移除拖拽
        resize: none;
        /* 移除默认外边框 */
        outline: none;
        /* 移除 WebKit 浏览器的默认样式 */
        -webkit-appearance: none;

        &::-moz-focus-inner {
          border: 0;
        }

        /* 移除 IE 的默认聚焦样式 */
        &:focus {
          outline: none;
        }
      }
    }
  }
}
</style>
